/* src/index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Arial", sans-serif;
  background-color: white;
  color: #000;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
