* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1{
  font-family: "Aclonica", sans-serif;
  font-size: 2rem;
  color: #ef3054;
}

body {
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

nav {
  background-color: #fff;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .logo {
  height: 50px;
}

nav .links a {
  margin: 0px 10px;
  text-decoration: none;
  color: #000;
}

.LOMAevents-icon {
  width: 150px;
}

#hero {
  background-image: url("./assets/antenna-ZDN-G1xBWHY-unsplash.jpg");
  background-size: cover;
  position: relative;
  text-align: center;
  padding: 30px 20px;
  color: white;
  font-family: "Aclonica", sans-serif;
}

#hero #gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#hero .hero-elements {
  position: relative;
  z-index: 2;
}

.hero-elements h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero-elements .slogan {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.app-links a img {
  height: 60px;
  margin: 10px;
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px;
}

.divider .line {
  width: 50px;
  height: 2px;
  background-color: white;
}

.divider .center {
  margin: 0 10px;
  font-size: 1.2rem;
}

#usps {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: left;
}

#usps h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.usp {
  display: flex;
  margin-bottom: 20px;
}

.usp img {
  height: 100px;
  margin-right: 20px;
}

.usp h3 {
  font-size: 1.5rem;
}

footer {
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
}

footer .logo {
  height: 40px;
  margin-bottom: 10px;
}

footer .links a {
  margin: 0 10px;
  text-decoration: none;
  color: #000;
}

footer .copyright {
  margin-top: 10px;
}

#flaticon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #000;
  font-size: x-small;
}
